<template>
  <div class="d-flex mt-2" style="flex-direction: column; overflow-y: auto; max-height: 350px">
    <div v-for="(verlaegerung, index) in groupedByLocation" :key="verlaegerung.id">
      <div>
        <!-- Name der Verlängerung -->
        <b>
          {{
            verlaegerung.beschreibung.includes('Verlängerungspaket')
              ? verlaegerung.beschreibung.replace('Verlängerungspaket', 'Paket')
              : verlaegerung.beschreibung.replace('Verlängerung', '').trim()
          }}
        </b>

        <div
          class="d-flex mt-2 justify-content-between"
          v-for="hotel in groupedHotels(verlaegerung.items)"
          :key="hotel.name"
        >
          <!-- Name des Hotels -->
          <div class="mr-2">{{ hotel.name }} ({{ getKuerzel(hotel.name, hotel.beschreibung) }}):</div>

          <div class="d-flex">
            <!-- Widgets für die Hotels -->
            <template>
              <div v-for="widget in hotel.widgets" :key="widget.id">
                <div class="einheiten-frei rounded" :class="istBgColor(widget.item) + ' mr-1'">
                  {{
                    widget.item.verfuegbarkeitsStatus == 'Verfuegbar'
                      ? widget.value
                      : widget.item.verfuegbarkeitsStatus == 'NichtVerfuegbar'
                      ? 'N'
                      : 'RQ'
                  }}
                </div>
                <!--  Hier steht dann "DZ" oder "EZ" -->
                <div class="text-center zimmertyp">
                  {{ widget.zimmertyp }}
                </div>
              </div>
            </template>
          </div>
        </div>
        <hr v-if="index < groupedByLocation.length - 1" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    verlaengerungen: {
      type: Array,
      required: true,
    },
    reiseterminId: {
      type: Number,
    },
  },
  computed: {
    groupedVerlaengerungen() {
      if (!this.verlaengerungen) return [];

      const processStatus = status => {
        // Filter nach Status
        const filtered = this.verlaengerungen.filter(v => v.verfuegbarkeitsStatus === status);

        // Gruppiere nach Beschreibung UND Hotelname
        const grouped = filtered.reduce((acc, curr) => {
          const key = `${curr.beschreibung}-${curr.name}`;
          if (!acc[key]) {
            acc[key] = {
              beschreibung: curr.beschreibung,
              verfuegbarkeitsStatus: status,
              name: curr.name,
              zimmertypen: {},
            };
          }
          // Füge Zimmertyp hinzu
          acc[key].zimmertypen[curr.zimmertyp] = curr.einheitenFrei;
          return acc;
        }, {});

        return Object.values(grouped);
      };

      const aufAnfrage = processStatus('AufAnfrage');
      const verfuegbar = processStatus('Verfuegbar');

      // Kombiniere die Ergebnisse und filtere leere Objekte
      return [...verfuegbar, ...aufAnfrage].filter(item => Object.keys(item.zimmertypen).length > 0);
    },

    groupedByLocation() {
      const groups = this.groupedVerlaengerungen.reduce((acc, item) => {
        if (!acc[item.beschreibung]) acc[item.beschreibung] = { beschreibung: item.beschreibung, items: [] };
        acc[item.beschreibung].items.push(item);
        return acc;
      }, {});

      return Object.values(groups);
    },
  },
  methods: {
    groupedHotels(items) {
      const hotelGroups = items.reduce((acc, item) => {
        // wenn das Hotel noch nicht im acc ist, dann wird ein neues Hotel Objekt erstellt
        if (!acc[item.name]) {
          acc[item.name] = {
            name: item.name,
            // das ist das Kürzel des Hotels
            beschreibung: item.beschreibung,
            // ein array von Widgets
            widgets: [],
          };
        }

        // Es werden hier die Widgets erstellt und in das widgets-Array gepusht
        Object.entries(item.zimmertypen).forEach(([zimmertyp, value]) => {
          acc[item.name].widgets.push({
            id: `${item.id}-${zimmertyp}`,
            zimmertyp, // "DZ" oder "EZ"
            value, // die Anzahl der Zimmer
            item, // referenz auf das ursprüngliche Item
          });
        });

        return acc;
      }, {});

      return Object.values(hotelGroups);
    },
    istBgColor(item) {
      return item.verfuegbarkeitsStatus == 'Verfuegbar'
        ? 'green'
        : item.verfuegbarkeitsStatus == 'NichtVerfuegbar'
        ? 'bg-danger'
        : 'gray';
    },
    getKuerzel(name, beschreibung) {
      if (this.verlaengerungen) {
        const kuerzel = this.verlaengerungen.find(
          item => item.name === name && item.beschreibung === beschreibung
        )?.kuerzel;
        return kuerzel;
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.bootstrap.scss';
.green {
  background-color: $success;
}
.einheiten-frei {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 30px;
  height: 25px;
  font-size: 1rem;
}
.gray {
  background-color: #e5eaee;
}
.zimmertyp {
  font-size: 1rem;
}
</style>
